.root {
  display: flex;
  filter: hue-rotate(10deg) saturate(50%);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  > img {
    filter: hue-rotate(10deg) saturate(50%) grayscale(1) brightness(400%);
    margin: 0.5em;
    transition-duration: 1s;
    transition-property: filter;
    transition-timing-function: ease-out;

    &:hover {
      // Brightness is a magic number here, because 100% looks too dark.
      filter: hue-rotate(10deg) saturate(50%) grayscale(0) brightness(175%);
    }
  }
}
