.root {
  align-items: center;
  background-size: 200% 200%, cover;
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  border-radius: 0.5em 0.5em 0 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: 240px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 100%;
}
