.button {
  background-origin: border-box !important;
  background-size: 100% 100% !important;
  border-width: 2px !important;
  box-sizing: border-box !important;
  color: transparent !important;
  font-size: 0 !important;
  user-select: none !important;
}

.listItem {
  margin-top: 0 !important;
}
