.avatar {
  border-radius: 50%;
}

.content {
  justify-content: center;
}

.p {
  max-width: 640px;
}
